import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';

// import VLWrapper from '@/components/widgets/ViralLoop';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useAuthenticate from '@/hooks/useAuthenticate';
import { useTheme } from '@/hooks/useTheme';
import { toggleTheme } from '@/redux/dark-mode/themeSlice';
import { cn } from '@/utils/helper';

import SEO from '../../SEO';
import Footer from '../Footer';
import Navbar from '../Navbar';
import NestedNavs from '../NestedNavs';

const DashboardLayout = dynamic(() => import('./DasboardLayout'));
interface IGeneralLayout {
  children: React.ReactNode;
  isPrivate: boolean;
  isPublic: boolean;
  RenderComponent?: React.ComponentType | (() => React.ReactNode);
  CustomNavbar?: React.ComponentType | (() => React.ReactNode);
  customStyle?: {
    navbar: string;
    container: string;
  };
  useHideDefaultSidebarSections?: () => boolean;
  disableMaxWidth?: boolean;
}
const GeneralLayout: React.FC<IGeneralLayout> = ({ children, isPrivate, isPublic, RenderComponent, CustomNavbar, customStyle, disableMaxWidth, useHideDefaultSidebarSections }) => {
  const { isAuth } = useAuthenticate();
  const { dynamicMetadata } = useAppSelector((state) => state.news);

  const [isAuthLocal, setISauthLocal] = useState<boolean | null>(null);

  const { mode } = useTheme();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathname = usePathname();
  let metaData;

  if (dynamicMetadata) {
    metaData = { ...dynamicMetadata };
  }
  const MetaComponent = metaData && <SEO contentType={metaData.contentType} image={metaData.image} title={metaData.title} description={metaData.description} />;

  useEffect(() => {
    if (isAuth !== null) {
      setISauthLocal(isAuth);
    }
    if (!isAuth) {
      dispatch(toggleTheme('dark'));
    }
  }, [dispatch, isAuth]);

  if (isAuthLocal === null) {
    return MetaComponent || null;
  }

  if (isPublic && isAuth) {
    router.push('/loans/active');
    return null;
  }

  if (isPrivate && !isAuth) {
    router.push('/');
    return null;
  }

  const IsStyle = pathname !== '/' && pathname !== '/loans' && pathname !== '/contact';
  return (
    <div id='general' className={`${mode} dark:bg-dark-mode`}>
      {MetaComponent}
      <div className='hidden lg:block'>{/* <VLWrapper /> */}</div>
      <div>
        {isAuth ? (
          <DashboardLayout RenderComponent={RenderComponent} CustomNavbar={CustomNavbar} customStyle={customStyle} useHideDefaultSidebarSections={useHideDefaultSidebarSections}>
            {children}
          </DashboardLayout>
        ) : (
          <>
            <Navbar />
            <div className='!bg-jet-black min-h-screen overflow-x-hidden'>
              <div className={cn(IsStyle ? cn(!disableMaxWidth && 'max-w-[1246px]', 'w-full m-auto') : '')}>
                {pathname !== '/' && <NestedNavs />}
                {children}
              </div>
            </div>
            <Footer />
            {/* <MobileStickyBottomNav dark={true} /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default GeneralLayout;
