import React, { useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Sheet } from 'react-modal-sheet';
import { useDispatch } from 'react-redux';

import BurgerIcon from '@/assets/icons/icon-buger.svg';
import CloseIcon from '@/assets/icons/icon-close-white.svg';
import SettingsIcon from '@/assets/icons/icon-setting.svg';
import PurpleLines from '@/assets/images/purple-lines.svg';
import { useAppSelector } from '@/hooks/redux';
import useScrollTop from '@/hooks/useScrollTop';
import { selectIsAuth } from '@/redux/auth/authSlice';
import { toggleMenuModal } from '@/redux/modal/modalSlice';
import { cn } from '@/utils/helper';

import StyledLink from './landing/home/StyledLink';

const navbarItems = [
  {
    text: 'Borrow',
    link: 'loans/borrow',
    isPrivate: true,
  },
  {
    text: 'Lend',
    link: 'loans/lend',
    isPrivate: true,
  },
  {
    text: 'Rewards',
    link: 'https://rewards.nettyworth.io/',
    newTab: true,
    isPrivate: false,
  },
  {
    text: 'API/SDK',
    link: '/api-sdk',
    isPrivate: false,
  },
  {
    text: 'Docs',
    link: 'https://docs.nettyworth.io/docs/welcome',
    newTab: true,
    isPrivate: false,
  },
  {
    text: 'Twitter',
    link: 'https://twitter.com/nettyworthapp',
    newTab: true,
    isPrivate: false,
  },
];

const mobileMenuLinks = [
  {
    id: 1,
    text: 'Home',
    link: '/',
    isPrivate: false,
  },
  {
    id: 2,
    text: 'Borrow',
    link: 'loans/borrow',
    isPrivate: true,
  },
  {
    id: 3,
    text: 'Lend',
    link: 'loans/lend',
    isPrivate: true,
  },
  {
    id: 4,
    text: 'Rewards',
    link: 'https://rewards.nettyworth.io/',
    newTab: true,
    isPrivate: false,
  },
  {
    id: 5,
    text: 'API/SDK',
    link: '/api-sdk',
    isPrivate: false,
  },
  {
    id: 6,
    text: 'Docs',
    link: 'https://docs.nettyworth.io/docs/welcome',
    newTab: true,
    isPrivate: false,
  },
  {
    id: 7,
    text: 'Twitter',
    link: 'https://twitter.com/nettyworthapp',
    newTab: true,
    isPrivate: false,
  },
];

const Navbar: React.FC<{ otherLinksHide?: boolean }> = ({ otherLinksHide }) => {
  const feedScroll = useScrollTop(50);
  const dispatch = useDispatch();
  const isAuth = useAppSelector(selectIsAuth);
  const { menuModal } = useAppSelector((state) => state.modal);
  const handleChange = () => dispatch(toggleMenuModal());
  const pathname = usePathname();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={cn('fixed top-0 w-full   px-[25px] py-6 left-0 right-0 z-30 transition-all ease-linear duration-150', feedScroll ? 'shadow-2xl' : 'shadow-none')}>
      <div className='max-w-screen-2xl mx-auto flex gap-6 justify-between items-center'>
        <Link href='/'>
          <Image src={'/logo-dark.svg'} width={140} height={48} alt='netty-logo' priority quality={100} className='object-contain min-w-[140px] h-14' />
        </Link>
        {!otherLinksHide && (
          <ul className='hidden lg:flex items-center  justify-between !bg-[#1A1A1A]  rounded-[84px] max-w-[901px] gap-3 flex-1 px-12'>
            {navbarItems.map(({ text, link, newTab, isPrivate }) => {
              return (
                <li key={text} className={cn(pathname === link ? '!bg-[rgb(255,255,255,0.12)]' : '!bg-transparent', 'w-full h-full flex justify-center items-center rounded-[84px] py-[9px]')}>
                  <Link target={newTab ? '_blank' : '_self'} href={isPrivate && !isAuth ? '/login' : link} className={cn('font-medium text-base/6  hover:!text-primary')}>
                    {text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
        {pathname !== '/login' && (
          <Link href='/login' className='w-[151px] h-[42px] hidden lg:flex justify-center items-center rounded-[332px] [background:_linear-gradient(180deg,_rgba(243,_238,_255,_0.00)_0%,_rgba(243,_238,_255,_0.04)_100%),_#FC77FE] text-white text-base/5 font-medium hover:opacity-75 active:scale-95'>
            Launch App
          </Link>
        )}

        <button onClick={toggleMobileMenu} type='button' className='lg:hidden size-6 hover:opacity-75 active:scale-95'>
          <BurgerIcon className='w-full h-full object-contain' />
        </button>

        <aside className={`lg:hidden fixed flex flex-col justify-between !bg-jet-black z-50 min-h-screen w-full top-0  pt-[40px] pb-6 px-[25px] transition-all duration-200 ${isMobileMenuOpen ? 'right-0' : '-right-full'}`}>
          <div>
            <div className='flex justify-between items-center gap-6'>
              {!isMobileMenuOpen && (
                <div className='size-10 flex items-center justify-center rounded-full !bg-black'>
                  <SettingsIcon className='[&_path]:fill-white' />
                </div>
              )}
              <div className='flex-1 flex items-center justify-end'>
                <button type='button' onClick={toggleMobileMenu} className='size-6 hover:opacity-75 active:scale-95 !bg-transparent'>
                  <CloseIcon />
                </button>
              </div>
            </div>

            <ul className='flex flex-col items-start gap-3 pt-7'>
              {mobileMenuLinks.map(({ id, link, text, newTab, isPrivate }) => (
                <Link key={id} onClick={() => setIsMobileMenuOpen(false)} href={isPrivate && !isAuth ? '/login' : link} target={newTab ? '_blank' : '_self'} className={`text-white w-full text-lg rounded-[102px] font-medium py-2 px-6 ${pathname === link ? '!bg-grey-coal-50' : ''}`}>
                  {text}
                </Link>
              ))}
            </ul>
          </div>

          <Link href='/login' onClick={() => setIsMobileMenuOpen(false)} className='md:w-[342px] w-full h-[42px] rounded-[118px] flex justify-center items-center [background:_linear-gradient(180deg,_rgba(243,_238,_255,_0.00)_0%,_rgba(243,_238,_255,_0.04)_100%),_#FC77FE] text-white font-medium px-6 py-2 hover:opacity-75 active:scale-95'>
            Launch App
          </Link>
        </aside>

        <Sheet isOpen={menuModal} onClose={handleChange} detent='content-height' className='sheet lg:hidden [&_.react-modal-sheet-container]:!max-h-screen [&_.react-modal-sheet-header]:bg-primary'>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className='h-screen bg-primary'>
                <button onClick={handleChange} className='absolute top-0 right-[26px] z-10'>
                  <CloseIcon />
                </button>
                <PurpleLines className='absolute -top-[35px]' />
                <div className='flex flex-col justify-center items-center pt-[100px]'>
                  <Image src={'/logo-dark.svg'} width={200} height={41} alt='netty-logo' priority quality={100} className='object-cover min-w-[200px] h-[41px]' />
                  <div className='flex flex-col gap-[19px] items-center mt-[59px]'>
                    {navbarItems.map(({ text, link }) => {
                      return (
                        <Link href={link} key={text} onClick={handleChange} className={cn('font-medium text-white text-[27px]/[31px]  hover:!text-primary')}>
                          {text}
                        </Link>
                      );
                    })}
                  </div>
                  <div className='flex items-center gap-[17px] mt-[38px]'>
                    <StyledLink className='bg-dark-violet' href='/login'>
                      login
                    </StyledLink>
                    <StyledLink className='bg-magenta w-[136px]' href='/login'>
                      sign up
                    </StyledLink>
                  </div>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    </nav>
  );
};

export default Navbar;
