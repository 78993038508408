import { getDefaultConfig } from 'connectkit';
import { createConfig, createConnector, http } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';

export const config = createConfig(
  getDefaultConfig({
    chains: [mainnet, sepolia],

    connectors: [
      coinbaseWallet({
        appName: 'wagmi.sh',
        enableMobileWalletLink: true,
      }),
      // metaMask({
      //   dappMetadata: {
      //     name: 'Wagmi',
      //     url: 'https://nettyworth.io/',
      //   },
      // }),
      injected({ target: 'metaMask' }),
      injected({ target: 'phantom' }),
      walletConnect({
        projectId: process.env.NEXT_PUBLIC_WC_PROJECTID,
        showQrModal: false,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '2000',
          },
        },
      }),
    ],
    appName: 'Nettyworth',
    ssr: true,
    transports: {
      [mainnet.id]: http(),
      [sepolia.id]: http(),
    },
    walletConnectProjectId: process.env.NEXT_PUBLIC_WC_PROJECTID,
  })
);
